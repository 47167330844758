import React from "react"
import ProductImg from "../ProductImg"
import ProductTitle from "../ProductTitle"
// import AddToCart from '../AddToCart';
import AddToCartBlues from "../AddToCartBlues"

const ConfirmIntro = ({
  product,
  handleSetVariant,
  handleAddToCart,
  addVariantToCart,
  shopifyId,
  quantity,
  truncated,
  backgroundColor,
  paperType,
  nextBackgroundColor,
  updateNextVariant,
}) => {
  //   console.log("[product, quantity, paperType]", [product, quantity, paperType])
  // const price = product.handle.includes('large') ? '$17.00' : '$14.00';
  // console.log("product", product)
  const discountPrice = "$" + product.priceRange.minVariantPrice.amount + "0"
  const originalPrice =
    "$" + product.priceRange.maxVariantPrice.amount
      ? product.priceRange.maxVariantPrice.amount + "0"
      : null

  const originalPricesObj = {
    "time-capsule": "20.00",
    "drawing-pad-classic": "17.00",
    "drawing-pad": "17.00",
    gratitude: "18.00",
    "gratitude-classic": "18.00",
    discovery: "17.00",
  }

  // console.log("product.priceRange", product.priceRange)
  // console.log('price', price);
  // truncated &&
  let styleAttr
  truncated
    ? (styleAttr = {
        background: "#F7FAFF",
        // background: backgroundColor,
        maxHeight: "200px",
        overflow: "hidden",
      })
    : (styleAttr = {
        background: "#F7FAFF",
      })

  return (
    // <div style={{ background: `${backgroundColor}` }} className="product-section-one-confirm-container">
    <div style={styleAttr} className="product-section-one-confirm-container">
      <div className="product-section-one-confirm-subcontainer">
        <ProductImg
          handle={product.handle}
          title={product.title}
          fluid={product.images[0].localFile.childImageSharp.fluid}
        />
        <ProductTitle
          handleSetVariant={handleSetVariant}
          handle={product.handle}
          title={product.title}
          paperType={paperType}
        />

        {/* <AddToCart  
					addVariantToCart={addVariantToCart}
					shopifyId={shopifyId}
					quantity={quantity}
					handleAddToCart={handleAddToCart}
				/> */}
        <AddToCartBlues
          title={product.title}
          paperType={paperType}
          currency={product.priceRange.maxVariantPrice.currencyCode}
          amount={product.priceRange.maxVariantPrice.amount}
          addVariantToCart={addVariantToCart}
          shopifyId={shopifyId}
          quantity={quantity}
          handleAddToCart={handleAddToCart}
        />

        {!originalPrice && (
          <div className="confirm-price">
            {discountPrice} <span className="usd">USD</span>
          </div>
        )}

        {originalPrice && (
          <div className="confirm-price-container">
            <div className="confirm-price-original">
              <span
                style={{
                  textDecoration: "line-through",
                  textDecorationColor: "#22B2F2",
                }}
              >
                ${originalPricesObj[product.handle]}
              </span>{" "}
              {/* <span className="usd">USD</span> */}
            </div>
            <div className="confirm-price-discount">
              {discountPrice}
              {/* <span className="usd">USD</span> */}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ConfirmIntro
