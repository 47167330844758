import "../styles/index.scss"
import React from "react"
import gsap, { TimelineMax, Linear } from "gsap"

import Img from "gatsby-image"

class ProductImg extends React.Component {
  constructor(props) {
    super(props)

    this.journalImg = null
    this.poof1 = null
    this.poof2 = null
    this.poof3 = null
    this.poof4 = null

    this.hoverTL = new TimelineMax({
      paused: true,
      repeat: -1,
    })
  }
  componentDidMount() {
    // console.log("props", this.props);
    gsap.set(this.poof1, { x: 10 })
    gsap.set(this.poof2, { x: 10 })
    gsap.set(this.poof3, { x: -10 })
    gsap.set(this.poof4, { x: -10 })
    this.hoverTL
      .add(this.levitateJournal(), "journal")
      .add(this.poofMagicOne(), "journal+=.32")
      .add(this.poofMagicTwo(), "journal+=.32")
      .add(this.poofMagicThree(), "journal+=.32")
      .add(this.poofMagicFour(), "journal+=.32")
      // .progress(1)
      // .progress(0)
      .timeScale(0.3)
      .play()
  }
  poofMagicOne = () => {
    /* ball shadow stretch child timeline */

    var tl = new TimelineMax()

    tl.to(
      this.poof1,
      0.35,
      {
        transformOrigin: "50% 50%",
        autoAlpha: 1,
        // scaleX: 1.7,
        // scaleY: 1.2,
        // delay: 0.05,
        yoyo: true,
        y: 5,
        x: -2,
        // ease: Power1.easeInOut
        ease: Linear.easeNone,
      },
      "begin"
    ).to(
      this.poof1,
      0.25,
      {
        transformOrigin: "50% 50%",
        autoAlpha: 0,
        // scaleX: 1.7,
        // scaleY: 1.2,
        // delay: 0.05,
        yoyo: true,
        y: 5,
        x: -2,
        // ease: Power1.easeInOut
        ease: Linear.easeNone,
      },
      "-=.2"
    )
    // .to(
    // 	this.poof2,
    // 	0.25,
    // 	{
    // 		transformOrigin: '50% 50%',
    // 		autoAlpha: 0,
    // 		// scaleX: 1.7,
    // 		// scaleY: 1.2,
    // 		// delay: 0.05,
    // 		yoyo: true,
    // 		y: 5,
    // 		x: -2,
    // 		// ease: Linear.easeNone
    // 		ease: Linear.easeNone
    // 	},
    // 	'begin'
    // )
    // .to(
    // 	this.poof3,
    // 	0.25,
    // 	{
    // 		transformOrigin: '50% 50%',
    // 		autoAlpha: 0,
    // 		// scaleX: 1.7,
    // 		// scaleY: 1.2,
    // 		// delay: 0.05,
    // 		yoyo: true,
    // 		y: 2,
    // 		x: 5,
    // 		// ease: Linear.easeNone
    // 		ease: Linear.easeNone
    // 	},
    // 	'begin'
    // )
    // .to(
    // 	this.poof4,
    // 	0.25,
    // 	{
    // 		transformOrigin: '50% 50%',
    // 		autoAlpha: 0,
    // 		// scaleX: 1.7,
    // 		// scaleY: 1.2,
    // 		// delay: 0.05,
    // 		yoyo: true,
    // 		y: -2,
    // 		x: 5,
    // 		// ease: Linear.easeNone
    // 		ease: Linear.easeNone
    // 	},
    // 	'begin'
    // );

    return tl
  }
  poofMagicTwo = () => {
    /* ball shadow stretch child timeline */

    var tl = new TimelineMax()

    tl.to(
      this.poof2,
      0.35,
      {
        transformOrigin: "50% 50%",
        autoAlpha: 1,
        // scaleX: 1.7,
        // scaleY: 1.2,
        // delay: 0.05,
        yoyo: true,
        y: 5,
        x: -2,
        // ease: Power1.easeInOut
        ease: Linear.easeNone,
      },
      "begin"
    ).to(
      this.poof2,
      0.25,
      {
        transformOrigin: "50% 50%",
        autoAlpha: 0,
        // scaleX: 1.7,
        // scaleY: 1.2,
        // delay: 0.05,
        yoyo: true,
        y: 5,
        x: -2,
        // ease: Power1.easeInOut
        ease: Linear.easeNone,
      },
      "-=.2"
    )
    // .to(
    // 	this.poof2,
    // 	0.25,
    // 	{
    // 		transformOrigin: '50% 50%',
    // 		autoAlpha: 0,
    // 		// scaleX: 1.7,
    // 		// scaleY: 1.2,
    // 		// delay: 0.05,
    // 		yoyo: true,
    // 		y: 5,
    // 		x: -2,
    // 		// ease: Linear.easeNone
    // 		ease: Linear.easeNone
    // 	},
    // 	'begin'
    // )
    // .to(
    // 	this.poof3,
    // 	0.25,
    // 	{
    // 		transformOrigin: '50% 50%',
    // 		autoAlpha: 0,
    // 		// scaleX: 1.7,
    // 		// scaleY: 1.2,
    // 		// delay: 0.05,
    // 		yoyo: true,
    // 		y: 2,
    // 		x: 5,
    // 		// ease: Linear.easeNone
    // 		ease: Linear.easeNone
    // 	},
    // 	'begin'
    // )
    // .to(
    // 	this.poof4,
    // 	0.25,
    // 	{
    // 		transformOrigin: '50% 50%',
    // 		autoAlpha: 0,
    // 		// scaleX: 1.7,
    // 		// scaleY: 1.2,
    // 		// delay: 0.05,
    // 		yoyo: true,
    // 		y: -2,
    // 		x: 5,
    // 		// ease: Linear.easeNone
    // 		ease: Linear.easeNone
    // 	},
    // 	'begin'
    // );

    return tl
  }
  poofMagicThree = () => {
    /* ball shadow stretch child timeline */

    var tl = new TimelineMax()

    tl.to(
      this.poof3,
      0.35,
      {
        transformOrigin: "50% 50%",
        autoAlpha: 1,
        // scaleX: 1.7,
        // scaleY: 1.2,
        // delay: 0.05,
        yoyo: true,
        y: 2,
        x: 5,
        // ease: Power1.easeInOut
        ease: Linear.easeNone,
      },
      "begin"
    ).to(
      this.poof3,
      0.25,
      {
        transformOrigin: "50% 50%",
        autoAlpha: 0,
        // scaleX: 1.7,
        // scaleY: 1.2,
        // delay: 0.05,
        yoyo: true,
        y: 2,
        x: 5,
        // ease: Power1.easeInOut
        ease: Linear.easeNone,
      },
      "-=.2"
    )
    // .to(
    // 	this.poof2,
    // 	0.25,
    // 	{
    // 		transformOrigin: '50% 50%',
    // 		autoAlpha: 0,
    // 		// scaleX: 1.7,
    // 		// scaleY: 1.2,
    // 		// delay: 0.05,
    // 		yoyo: true,
    // 		y: 5,
    // 		x: -2,
    // 		// ease: Linear.easeNone
    // 		ease: Linear.easeNone
    // 	},
    // 	'begin'
    // )
    // .to(
    // 	this.poof3,
    // 	0.25,
    // 	{
    // 		transformOrigin: '50% 50%',
    // 		autoAlpha: 0,
    // 		// scaleX: 1.7,
    // 		// scaleY: 1.2,
    // 		// delay: 0.05,
    // 		yoyo: true,
    // 		y: 2,
    // 		x: 5,
    // 		// ease: Linear.easeNone
    // 		ease: Linear.easeNone
    // 	},
    // 	'begin'
    // )
    // .to(
    // 	this.poof4,
    // 	0.25,
    // 	{
    // 		transformOrigin: '50% 50%',
    // 		autoAlpha: 0,
    // 		// scaleX: 1.7,
    // 		// scaleY: 1.2,
    // 		// delay: 0.05,
    // 		yoyo: true,
    // 		y: -2,
    // 		x: 5,
    // 		// ease: Linear.easeNone
    // 		ease: Linear.easeNone
    // 	},
    // 	'begin'
    // );

    return tl
  }
  poofMagicFour = () => {
    /* ball shadow stretch child timeline */

    var tl = new TimelineMax()

    tl.to(
      this.poof4,
      0.35,
      {
        transformOrigin: "50% 50%",
        autoAlpha: 1,
        // scaleX: 1.7,
        // scaleY: 1.2,
        // delay: 0.05,
        yoyo: true,
        y: -2,
        x: 5,
        // ease: Power1.easeInOut
        ease: Linear.easeNone,
      },
      "begin"
    ).to(
      this.poof4,
      0.25,
      {
        transformOrigin: "50% 50%",
        autoAlpha: 0,
        // scaleX: 1.7,
        // scaleY: 1.2,
        // delay: 0.05,
        yoyo: true,
        y: -2,
        x: 5,
        // ease: Power1.easeInOut
        ease: Linear.easeNone,
      },
      "-=.2"
    )
    // .to(
    // 	this.poof2,
    // 	0.25,
    // 	{
    // 		transformOrigin: '50% 50%',
    // 		autoAlpha: 0,
    // 		// scaleX: 1.7,
    // 		// scaleY: 1.2,
    // 		// delay: 0.05,
    // 		yoyo: true,
    // 		y: 5,
    // 		x: -2,
    // 		// ease: Linear.easeNone
    // 		ease: Linear.easeNone
    // 	},
    // 	'begin'
    // )
    // .to(
    // 	this.poof3,
    // 	0.25,
    // 	{
    // 		transformOrigin: '50% 50%',
    // 		autoAlpha: 0,
    // 		// scaleX: 1.7,
    // 		// scaleY: 1.2,
    // 		// delay: 0.05,
    // 		yoyo: true,
    // 		y: 2,
    // 		x: 5,
    // 		// ease: Linear.easeNone
    // 		ease: Linear.easeNone
    // 	},
    // 	'begin'
    // )
    // .to(
    // 	this.poof4,
    // 	0.25,
    // 	{
    // 		transformOrigin: '50% 50%',
    // 		autoAlpha: 0,
    // 		// scaleX: 1.7,
    // 		// scaleY: 1.2,
    // 		// delay: 0.05,
    // 		yoyo: true,
    // 		y: -2,
    // 		x: 5,
    // 		// ease: Linear.easeNone
    // 		ease: Linear.easeNone
    // 	},
    // 	'begin'
    // );

    return tl
  }
  levitateJournal = () => {
    /* ball shadow stretch child timeline */

    var tl = new TimelineMax()

    tl.to(this.journalImg, 0.5, {
      transformOrigin: "50% 50%",
      // autoAlpha: 0.15,
      // scaleX: 1.7,
      // scaleY: 1.2,
      y: 15,
      // ease: Power1.easeInOut
      ease: Linear.easeNone,
    }) //, "bounce"
      /* ball shadow normal */
      .to(this.journalImg, 0.5, {
        transformOrigin: "50% 50%",
        // autoAlpha: 1,
        // scaleX: 1,
        // scaleY: 1,
        y: 0,
        ease: Linear.easeNone,
        // ease: Power1.easeIn
      }) //, "bounce2"

    return tl.timeScale(1.5)
  }

  render() {
    return (
      <div className="image-poofs-wrapper">
        <div
          ref={el => (this.journalImg = el)}
          //   className="levitating-product-image"
          className={`levitating-product-image ${
            this?.props?.handle === "time-capsule" ||
            this?.props?.handle === "drawing-pad" ||
            this?.props?.handle === "drawing-pad-classic"
              ? "levitating-product-image-wide"
              : ""
          }`}
        >
          <Img title={this.props.title} fluid={this.props.fluid} />
        </div>

        <svg
          //   className="poofs"
          className={`poofs  ${
            this?.props?.handle === "time-capsule" ||
            this?.props?.handle === "drawing-pad" ||
            this?.props?.handle === "drawing-pad-classic"
              ? "poofs-wide"
              : ""
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 152.32 28.84"
        >
          <path
            style={{ opacity: "0" }}
            ref={el => (this.poof4 = el)}
            d="M141.54 7.14a10.71 10.71 0 004-2s-3.29-.29-2.7-3.44c0 0 .76-2.12 3.6-1.07 0 0 4.29-2.05 4.9 3.34 0 0 2 3.24-.48 5.63 0 0-.82 1.5-2.28-.21 0 0-1.79 2.47-3.25.33 0 0-.62-2.33.87-3.13a8.14 8.14 0 01-4.66.55z"
            fill="none"
            stroke="#000202"
            strokeMiterlimit="10"
            strokeWidth=".5"
            id="poof4"
          />
          <path
            style={{ opacity: "0" }}
            ref={el => (this.poof3 = el)}
            d="M118.8 18.29a46 46 0 0012.61.1s-4.42-2.28.2-3c0 0-2.76-1.6.17-4.18 0 0 2.67-1.75 5.35 1 0 0 5.81 1.16 6 5.86a5 5 0 01-2.9 4.52 5.66 5.66 0 01-5.07 4.83s-1.82.91-2.64-2.08a2.81 2.81 0 01-3-2 7.16 7.16 0 011.62-2.19 29.58 29.58 0 01-12.34-2.86z"
            fill="none"
            stroke="#000202"
            strokeMiterlimit="10"
            strokeWidth=".5"
            id="poof3"
          />
          <path
            style={{ opacity: "0" }}
            ref={el => (this.poof2 = el)}
            d="M25.03 18.45a10.73 10.73 0 00-1.9 4s2.76-1.82 4.25 1c0 0 .73 2.12-2.15 3.08 0 0-2.08 4.28-5.91.43 0 0-3.55-1.32-3.13-4.7 0 0-.29-1.68 1.91-1.26 0 0-.14-3 2.34-2.28 0 0 1.94 1.44 1.26 3a8.22 8.22 0 013.33-3.27z"
            fill="none"
            stroke="#000202"
            strokeMiterlimit="10"
            strokeWidth=".5"
            id="poof2"
          />
          <path
            style={{ opacity: "0" }}
            ref={el => (this.poof1 = el)}
            d="M21.84 12.25a33.54 33.54 0 01-7.71-1.66s2.52-.64.53-2.51c0 0 1.35-9-8.22-3.24 0 0-6.42-.38-4.79 6.23 0 0-3.08 2.74-.11 5.62a3.19 3.19 0 004.65.49s3.63 3.9 5.58-2.2l-.83-.35 1.61-.62-2.27-.56s8.3.13 11.56-1.2z"
            fill="none"
            stroke="#000202"
            strokeMiterlimit="10"
            strokeWidth=".5"
            id="poof1"
          />
        </svg>

        {/* 

								<svg
					style={{ width: '16rem', transform: 'translateY(-6rem)', willChange: 'transform' }}
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 152.32 28.18"
				>
					<path
						ref={(el) => (this.poof4 = el)}
						style={{ opacity: '0' }}
						d="M141.54 7.5a10.71 10.71 0 004-2s-3.29-.29-2.7-3.44c0 0 .76-2.12 3.6-1.07 0 0 4.29-2.05 4.9 3.34 0 0 2 3.24-.48 5.63 0 0-.82 1.5-2.28-.21 0 0-1.79 2.47-3.25.33 0 0-.62-2.33.87-3.13a8.14 8.14 0 01-4.66.55z"
						fill="none"
						stroke="#000202"
						strokeMiterlimit="10"
						strokeWidth=".5"
						id="poof4"
					/>
					<path
						ref={(el) => (this.poof3 = el)}
						style={{ opacity: '0' }}
						d="M118.8 18.65a46 46 0 0012.61.1s-4.42-2.28.2-3c0 0-2.76-1.6.17-4.18 0 0 2.67-1.75 5.35 1 0 0 5.81 1.16 6 5.86a5 5 0 01-2.9 4.52 5.66 5.66 0 01-5.07 4.83s-1.82.91-2.64-2.08a2.81 2.81 0 01-3-2 7.16 7.16 0 011.62-2.19 29.58 29.58 0 01-12.34-2.86z"
						fill="none"
						stroke="#000202"
						strokeMiterlimit="10"
						strokeWidth=".5"
						id="poof3"
					/>
					<path
						ref={(el) => (this.poof2 = el)}
						style={{ opacity: '0' }}
						d="M27.26 7.9a10.55 10.55 0 00-4.39-.78s2.46 2.2.09 4.36c0 0-1.87 1.24-3.52-1.3 0 0-4.66-.93-1.92-5.61 0 0 .39-3.76 3.76-4.21 0 0 1.56-.71 1.7 1.52 0 0 2.91-.9 2.8 1.69 0 0-.9 2.24-2.58 2a8.22 8.22 0 014.06 2.33z"
						fill="none"
						stroke="#000202"
						strokeMiterlimit="10"
						strokeWidth=".5"
						id="poof2"
					/>
					<path
						ref={(el) => (this.poof1 = el)}
						style={{ opacity: '0' }}
						d="M21.84 18.28a33.54 33.54 0 01-7.71-1.66s2.52-.64.53-2.51c0 0 1.35-9.05-8.22-3.24 0 0-6.42-.38-4.79 6.23 0 0-3.08 2.74-.11 5.62a3.19 3.19 0 004.65.49s3.63 3.9 5.58-2.2l-.83-.35 1.61-.62-2.27-.56s8.3.13 11.56-1.2z"
						fill="none"
						stroke="#000202"
						strokeMiterlimit="10"
						strokeWidth=".5"
						id="poof1"
					/>
				</svg>
				 */}
      </div>
    )
  }
}

export default ProductImg
