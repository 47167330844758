import React from "react"
import Img from "gatsby-image"

const ConfirmGrid = ({ carouselImages }) => {
  // console.log("carouselImages", carouselImages)
  const one = carouselImages[0] || null
  const two = carouselImages[1] || null
  const three = carouselImages[2] || null
  const four = carouselImages[3] || null
  const five = carouselImages[4] || null
  const six = carouselImages[5] || null
  const seven = carouselImages[6] || null
  const eight = carouselImages[7] || null
  const nine = carouselImages[8] || null
  const ten = carouselImages[9] || null

  return (
    // <div className="confirm-grid">
    //   <div className="confirm-grid__container">
    //     <div className="confirm-grid__item confirm-grid__item-c2">
    //       {two && <Img fluid={two.localFile.childImageSharp.fluid} />}
    //     </div>
    //     <div className="confirm-grid__item-rotate">
    //       {one && <Img fluid={one.localFile.childImageSharp.fluid} />}
    //     </div>
    //     <div className="confirm-grid__item confirm-grid__item-r2">
    //       {three && <Img fluid={three.localFile.childImageSharp.fluid} />}
    //     </div>
    //     <div className="confirm-grid__item">
    //       {four && <Img fluid={four.localFile.childImageSharp.fluid} />}
    //     </div>
    //     <div className="confirm-grid__item">
    //       {five && <Img fluid={five.localFile.childImageSharp.fluid} />}
    //     </div>
    //     <div className="confirm-grid__item">
    //       {six && <Img fluid={six.localFile.childImageSharp.fluid} />}
    //     </div>
    //   </div>
    // </div>
    <div className="showcase-images">
      {two && (
        <div className="showcase-images__img">
          <Img fluid={two.localFile.childImageSharp.fluid} />
        </div>
      )}
      {three && (
        <div className="showcase-images__img">
          <Img fluid={three.localFile.childImageSharp.fluid} />
        </div>
      )}
      {four && (
        <div className="showcase-images__img">
          <Img fluid={four.localFile.childImageSharp.fluid} />
        </div>
      )}
      {five && (
        <div className="showcase-images__img">
          <Img fluid={five.localFile.childImageSharp.fluid} />
        </div>
      )}
      {six && (
        <div className="showcase-images__img">
          <Img fluid={six.localFile.childImageSharp.fluid} />
        </div>
      )}
      {seven && (
        <div className="showcase-images__img">
          <Img fluid={seven.localFile.childImageSharp.fluid} />
        </div>
      )}
      {eight && (
        <div className="showcase-images__img">
          <Img fluid={eight.localFile.childImageSharp.fluid} />
        </div>
      )}
      {nine && (
        <div className="showcase-images__img">
          <Img fluid={nine.localFile.childImageSharp.fluid} />
        </div>
      )}
      {ten && (
        <div className="showcase-images__img">
          <Img fluid={ten.localFile.childImageSharp.fluid} />
        </div>
      )}
    </div>
  )
}

export default ConfirmGrid

/* 
<Img
  className="otherstickergrid-image"
  fluid={otherProductOne.node.images[0].localFile.childImageSharp.fluid}/>
*/
