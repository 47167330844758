import React, { useState, useEffect, useRef } from "react"
import { fadeIn, fadeOut, revealBlues } from "./Animations"
import ReactPixel from "react-facebook-pixel"
import { Player } from "@lottiefiles/react-lottie-player"
import gsap from "gsap"

const AddToCartBlues = ({
  title,
  paperType,
  currency,
  amount,
  addVariantToCart,
  shopifyId,
  quantity,
}) => {
  const [tracking, setTracking] = useState(false)
  const button = useRef()
  const lines = useRef()
  const heart1 = useRef()
  const heart2 = useRef()
  const heart3 = useRef()
  const flakes1 = useRef()
  const flakes2 = useRef()
  const flakes3 = useRef()
  const [lottie, setLottie] = useState(null)

  const handlePlay = () => {
    console.log("lottie", lottie)
    lottie.goToAndPlay(0, true)
  }

  const handleAddToCart = () => {
    handlePlay()

    // gsap
    //   .timeline()
    //   .add("start")
    //   .to(
    //     lines.current,
    //     {
    //       autoAlpha: 1,
    //       duration: 0.05,
    //     },
    //     "start"
    //   )
    //   .to(
    //     lines.current,
    //     {
    //       autoAlpha: 0,
    //       duration: 0.3,
    //     },
    //     "start+=.75"
    //   )
    if (!tracking) {
      ReactPixel.track("AddToCart", {
        title,
        paperType,
        currency,
        value: amount,
        quantity,
      })
    }

    // console.log("{}", { title, paperType, currency, amount, quantity })

    setTracking(true)
    addVariantToCart(shopifyId, quantity)
  }

  // const handleHoverButton = () => {
  // 	fadeIn(lines.current);
  // };
  // const handleHoverExitButton = () => {
  // 	fadeOut(lines.current, 0.015);
  // };

  // useEffect(() => {
  // console.log('heart1.current', heart1.current);
  // gsap.set(heart1.current, { autoAlpha: 1, y: '30' });
  // gsap.set(heart2.current, { autoAlpha: 1, y: '50' });
  // gsap.set(heart3.current, { autoAlpha: 1, y: '75' });
  // gsap.set(flakes1.current, { autoAlpha: 1 });
  // gsap.set(flakes2.current, { autoAlpha: 1 });
  // gsap.set(flakes3.current, { autoAlpha: 1, y: '60' });
  // }, []);

  return (
    <div
      // onMouseOver={handleHoverButton}
      // onMouseLeave={handleHoverExitButton}
      onClick={handleAddToCart}
      id="add-to-cart-blues-button"
    >
      <Player
        lottieRef={instance => {
          setLottie(instance) // the lottie instance is returned in the argument of this prop. set it to your local state
        }}
        // onEvent={(event) => {
        //   console.log("event", event);
        // }}
        autoplay={false}
        loop={false}
        // ref={lottie}
        className="lottie"
        src="https://assets8.lottiefiles.com/packages/lf20_u4yrau.json"
        style={{
          height: "120%",
          width: "120%",
          position: "absolute",
          // top: "-3rem",
          top: "-9vw",
          left: "-2.5vw",
        }}
      ></Player>
      <svg
        ref={button}
        className="add-to-cart-blues-button-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128.28 112.75"
      >
        <g id="blues-button" strokeMiterlimit="10">
          <rect
            x="10.46"
            y="68.15"
            width="106.31"
            height="32.62"
            rx="4.14"
            ry="4.14"
            // fill="#22b2f2"
            stroke="#344357"
          />
          <text
            transform="translate(20.71 89.26)"
            fontSize="12"
            fill="#fff"
            stroke="#fff"
            fontFamily="BigJohnPRO-Regular, Big John PRO"
            letterSpacing=".11em"
          >
            Add{" "}
            <tspan x="34.41" y="0" letterSpacing="0em">
              T
            </tspan>
            <tspan x="43.35" y="0">
              o{" "}
            </tspan>
            <tspan x="56.69" y="0" letterSpacing=".09em">
              C
            </tspan>
            <tspan x="68.28" y="0">
              art
            </tspan>
          </text>
        </g>
        <g
          ref={lines}
          id="blues-lines"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        >
          <path d="M94.7 111.32l-2.68-7.14M60.75 63.44l-2.67-7.14M70.89 110.57l2.91-6.76M52.86 112.72l-.52-7.34M106.3 63.54l-.52-7.34M29.32 111.09l3.68-6.37M38.76 63.78l3.68-6.37M128.18 75.92l-7.22 1.39M7.32 90.4L.09 91.79M118.52 101.87l4.06 3.2M77.67 59l4.05 3.2M13.57 61.12l4.05 3.2M122.56 92.74l3.98-3.29M1.31 78.25l3.98-3.28M6.71 108.3l3.28-3.98" />
        </g>
        <path
          ref={heart3}
          d="M52.8 6.01S55.09-1.68 62.36.9c0 0 5 3.87 2.07 9 0 0-.82 3.74-10.26 12.51 0 0-10.41-2.79-13.42-10.91 0 0-3-6.7 3.94-10 0 .03 5.45-1.9 8.11 4.51z"
          fill="#0caef2"
          stroke="#181818"
          strokeMiterlimit="10"
          strokeWidth=".75"
          id="blues-heart3"
          className="deco"
        />
        <path
          ref={flakes3}
          d="M101.97 22.83a13.81 13.81 0 014.73 1.29s-.5-6.36-1.33-8.22a28.21 28.21 0 00-4.86.22s1.74 4.98 1.46 6.71z"
          fill="#f2efd0"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth=".75"
          id="blues-flakes3"
          className="deco"
        />
        <g
          ref={flakes2}
          id="blues-flakes2"
          fill="#e4c1d3"
          className="deco"
          strokeMiterlimit="10"
          strokeWidth=".75"
        >
          <path
            d="M102.04 38.61s7.8-4.61 8.39-5.79c0 0 1.16 5 2.61 6.39 0 0-6.18 5.15-9.37 6.09 0 0-.53-4.8-1.63-6.69z"
            stroke="#000"
          />
          <path
            d="M16.34 36.55s-.55 8.38.17 11.39a153.62 153.62 0 0114.9-5.45s.89-5.61 0-9.52c-.04 0-6.55-.67-15.07 3.58z"
            stroke="#181818"
          />
        </g>
        <path
          ref={heart2}
          className="deco"
          d="M66.08 40.52a14.7 14.7 0 0013.7-5.64s3.28-5-1.83-7.15c0 0-4.71-1.63-6.78 2.65 0 0 0-5.23-3.41-6.36 0 0-5.67-.91-6.23 5.91 0 0-.39 4.85 4.55 10.59z"
          fill="#7accef"
          stroke="#181818"
          strokeMiterlimit="10"
          strokeWidth=".75"
          id="blues-heart2"
        />
        <g
          className="deco"
          ref={flakes1}
          id="blues-flakes1"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth=".75"
        >
          <path
            d="M45.86 38.97c-.57-.59-3.81 3.06-3.81 3.06s2.06 2.26 2.21 3.84c0 0 3.32-.52 4.48-2.18a24 24 0 00-2.88-4.72z"
            fill="#bcdec1"
          />
          <path
            d="M76.9 53.26l2.69-5.09s5.38 3.94 6.72 3.41a44.44 44.44 0 00-2.55 4.67 9.66 9.66 0 01-6.86-2.99z"
            fill="#ffead8"
          />
        </g>
        <path
          className="deco"
          ref={heart1}
          d="M60.93 55.18s.6-3.68 3.19-2.8c0 0 2.31 1.05.9 4.08 0 0-2.42 2.79-2.13 4 0 0-5.44.34-5.9-3.94 0 0 .95-3.34 3.94-1.34z"
          fill="#a6e1f9"
          stroke="#181818"
          strokeMiterlimit="10"
          strokeWidth=".75"
          id="blues-heart1"
        />
      </svg>
    </div>
  )
}

export default AddToCartBlues
