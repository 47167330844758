// — › >
import "../styles/index.scss"
import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import gsap from "gsap"
import { fadeInConfirmHeading } from "./Animations"

const ProductTitle = ({ handle, title, paperType, handleSetVariant }) => {
  const titleNode = useRef()
  // const navHistoryNode = useRef();

  useEffect(() => {
    gsap.set(titleNode.current, { autoAlpha: 1 })
    // gsap.set(navHistoryNode.current, { autoAlpha: 1 });
    // fadeInConfirmHeading(navHistoryNode.current, titleNode.current);
  }, [])
  return (
    <div className="product-specs-paper-mini-options-wrapper">
      {/* <div className="product-specs-paper-mini-options-container">

				<div className="confirm-intro-paper-type">
					<b>Paper</b>:{' '}
					<span style={{ fontStyle: 'italic', marginLeft: '.25rem', textTransform: 'capitalize' }}>
						{paperType}
					</span>{' '}
				</div>

				<div
					className={`mini-paper-ruled ${paperType === 'ruled' ? 'color-choice' : ''}`}
					onClick={() => handleSetVariant(0, 'ruled')}
				>
					<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.44 69.44">
						<circle cx="34.72" cy="34.72" r="34.22" fill="none" strokeMiterlimit="10" />
						<path
							fill="none"
							strokeMiterlimit="10"
							d="M9.52 11.85h50.59M.5 34.5h68.44M2.07 23.21H66.9M2.58 46.13h64.48M9.34 57.59h50.82"
						/>
					</svg>
				</div>
				<div
					className={`mini-paper-bullet  ${paperType === 'bullet' ? 'color-choice' : ''}`}
					onClick={() => handleSetVariant(2, 'bullet')}
				>
					<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.44 69.44">
						<circle cx="34.72" cy="34.72" r="34.22" fill="none" strokeMiterlimit="10" />
						<circle cx="13.65" cy="13.66" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="13.65" cy="24.1" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="13.65" cy="34.54" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="13.65" cy="45.21" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="13.64" cy="55.77" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="55.8" cy="13.66" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="55.8" cy="24.09" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="55.8" cy="34.53" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="55.8" cy="45.21" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="55.79" cy="55.76" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="24.08" cy="55.76" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="24.08" cy="34.51" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="24.08" cy="45.22" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="24.08" cy="24.11" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="24.08" cy="13.65" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="1.79" cy="34.51" r=".17" strokeMiterlimit="10" strokeWidth="3" />
						<circle cx="3.61" cy="45.22" r=".17" strokeMiterlimit="10" strokeWidth="3" />
						<circle cx="3.61" cy="24.11" r=".17" strokeMiterlimit="10" strokeWidth="3" />
						<circle cx="34.96" cy="13.7" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="34.96" cy="1.72" r=".17" strokeMiterlimit="10" strokeWidth="3" />
						<circle cx="34.96" cy="24.14" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="34.96" cy="34.58" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="34.96" cy="45.25" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="34.96" cy="55.8" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="45.39" cy="55.8" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="45.39" cy="34.55" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="45.34" cy="45.22" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="45.39" cy="24.15" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="45.39" cy="13.69" r=".17" strokeMiterlimit="10" strokeWidth="4" />
						<circle cx="67.71" cy="34.51" r=".17" strokeMiterlimit="10" strokeWidth="3" />
						<circle cx="65.7" cy="45.22" r=".17" strokeMiterlimit="10" strokeWidth="3" />
						<circle cx="65.75" cy="24.15" r=".17" strokeMiterlimit="10" strokeWidth="3" />
						<circle cx="45.16" cy="3.49" r=".17" strokeMiterlimit="10" strokeWidth="3" />
						<circle cx="24.08" cy="3.73" r=".17" strokeMiterlimit="10" strokeWidth="3" />
						<circle cx="34.96" cy="67.7" r=".17" strokeMiterlimit="10" strokeWidth="3" />
						<circle cx="45.33" cy="65.98" r=".17" strokeMiterlimit="10" strokeWidth="3" />
						<circle cx="24.21" cy="65.98" r=".17" strokeMiterlimit="10" strokeWidth="3" />
					</svg>
				</div>

				<div
					onClick={() => handleSetVariant(1, 'plain')}
					className={`mini-paper-plain ${paperType === 'plain' ? 'color-choice' : ''}`}
				>
					<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.44 69.44">
						<circle cx="34.72" cy="34.72" r="34.22" fill="none" strokeMiterlimit="10" />
					</svg>
				</div>

			</div>
			 */}

      <div
        style={{ fontFamily: "Alice", color: "#00a8f3" }}
        className="product-specs-detail"
      >
        <b>Highlights:</b>
      </div>

      <div className="product-specs-detail">
        Delve deeper into your child’s day and spark a consistent habit of
        positive discussion.
      </div>
      <div className="product-specs-detail">
        Instill a foundation of gratitude and positivity for long term positive
        outlook.
      </div>

      <div
        style={{ fontFamily: "Alice", color: "#00a8f3" }}
        className="product-specs-detail"
      >
        <b>Summary:</b>
      </div>
      <div className="product-specs-detail">
        Our gratitude journal is great for laying a foundation of perspective
        early on in life. A perspective that ultimately cultivates a long term
        positive outlook.
      </div>
      <div className="product-specs-detail">
        Moreover, we found that for many of our customers, the gratitude journal
        serves as a gateway for consistent and positive discussions as your
        child reveals the various events throughout their day.
      </div>
      <div className="product-specs-detail">
        <b>4 months</b> of journaling
      </div>
      <div className="product-specs-detail">
        <b>122</b> pages
      </div>
      <div className="product-specs-detail">
        <b>Cover</b>: Sturdy Hardcover
      </div>
      <div className="product-specs-detail">
        <b>Size</b>: 5.5 x 8.5” / 140 x 216 mm
      </div>
    </div>
  )
}

export default ProductTitle
